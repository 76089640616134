import { defineStore } from "pinia";
import { doc, type DocumentReference } from "firebase/firestore";
import { ref as storRef } from "firebase/storage";

export const useVendor = defineStore("vendor", () => {
  const { uid } = storeToRefs(useUser());

  const vendorRef = computed(() => {
    const { $firebaseFirestore } = useNuxtApp();
    if (uid.value && $firebaseFirestore)
      return doc($firebaseFirestore, "vendors", uid.value) as DocumentReference<Vendor>;
  });
  const vendor = useFirestore(vendorRef);

  const storageRef = computed(() => {
    const { $firebaseStorage } = useNuxtApp();
    if (uid.value && $firebaseStorage) return storRef($firebaseStorage, `vendors/${uid.value}`);
  });

  // Get the vendor's orders
  const { $authFetch } = useNuxtApp();
  const { data: orders, refresh: refreshOrders } = useAsyncData(
    "vendor-orders",
    () => $authFetch<Order[]>(`/api/vendor/${uid.value}/orders`),
    {
      default: () => [],
      server: false,
      watch: [uid],
    }
  );

  return { vendor, vendorRef, storageRef, orders, refreshOrders };
});
