import { get } from "@vueuse/core";
import type { UserRecord } from "firebase-admin/auth";
import type { User } from "firebase/auth";
import { defineStore } from "pinia";

export const useUser = defineStore("user", () => {
  const client = shallowRef<User | null>(null);
  const loggedIn = computed(() => !!get(client));
  const anonymous = computed(() => get(client)?.isAnonymous);
  const uid = computed(() => get(client)?.uid);
  const name = computed(() => {
    if (!meta.value?.firstName && !meta.value?.lastName) return client.value?.displayName ?? "";
    return `${meta.value?.firstName} ${meta.value?.lastName}`;
  });
  const phone = computed(() => get(meta)?.phone || get(customer)?.phone);

  const server = shallowRef<UserRecord | null>();
  const email = computed(() => get(client)?.email || get(server)?.email || get(customer)?.email);
  const role = computed<role>(() => server.value?.customClaims?.role);
  const isAdmin = computed(() => get(role) === "admin");
  const isVendor = computed(() => get(isAdmin) || get(role) === "vendor");
  const isCustomer = computed(() => get(isAdmin) || get(role) === "customer");
  const isDriver = computed(() => get(isAdmin) || get(role) === "driver");

  const metaDoc = computed(() => (get(uid) ? userMetaRef(get(uid)) : null));
  const meta = useFirestore(metaDoc);

  const customerDoc = computed(() => (isCustomer.value ? customerRef(get(uid)) : null));
  const customer = useFirestore(customerDoc);

  const ready = new Promise<void>((resolve) => {
    if (import.meta.server) return resolve();
    watch(server, (server) => {
      if (server !== undefined) return resolve();
    });
  });

  return {
    client,
    server,
    uid,
    anonymous,
    loggedIn,
    isAdmin,
    isVendor,
    isCustomer,
    isDriver,
    role,
    name,
    phone,
    meta,
    customer,
    email,
    ready,
  };
});
