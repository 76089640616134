import { defineStore } from "pinia";

export const useDriver = defineStore("driver", () => {
  const { uid } = storeToRefs(useUser());
  const { $authFetch } = useNuxtApp();

  // Fetch the driver's orders
  const { data: orders, refresh: refreshOrders } = useAsyncData(
    "driver-orders",
    () => $authFetch<OrderWithDelivery[]>(`/api/driver/${uid.value}/orders`),
    {
      default: () => [],
      server: false,
      watch: [uid],
    }
  );

  const availableJobs = useArrayFilter(
    orders,
    ({ deliveryDetails }) => deliveryDetails.driverOffer === uid.value
  );
  const activeJobs = useArrayFilter(
    orders,
    ({ deliveryDetails }) => deliveryDetails.driver === uid.value
  );

  return { orders, refreshOrders, availableJobs, activeJobs };
});
