import validate from "/home/runner/work/delivery-doves/delivery-doves/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.16.10_ioredis@5.4.1_magicast@0.3.5_rollup@4._wmtra2nrrahpfyj5gbmobsxqsy/node_modules/nuxt/dist/pages/runtime/validate.js";
import admin_45global from "/home/runner/work/delivery-doves/delivery-doves/middleware/admin.global.ts";
import manifest_45route_45rule from "/home/runner/work/delivery-doves/delivery-doves/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.16.10_ioredis@5.4.1_magicast@0.3.5_rollup@4._wmtra2nrrahpfyj5gbmobsxqsy/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  admin_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/home/runner/work/delivery-doves/delivery-doves/middleware/auth.ts"),
  "logged-out": () => import("/home/runner/work/delivery-doves/delivery-doves/middleware/logged-out.ts")
}