import revive_payload_client_GkJiFtbmMd from "/home/runner/work/delivery-doves/delivery-doves/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.16.10_ioredis@5.4.1_magicast@0.3.5_rollup@4._wmtra2nrrahpfyj5gbmobsxqsy/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_Bx1BGvbjT7 from "/home/runner/work/delivery-doves/delivery-doves/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.16.10_ioredis@5.4.1_magicast@0.3.5_rollup@4._wmtra2nrrahpfyj5gbmobsxqsy/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_Y2PcTd7Mt0 from "/home/runner/work/delivery-doves/delivery-doves/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.16.10_ioredis@5.4.1_magicast@0.3.5_rollup@4._wmtra2nrrahpfyj5gbmobsxqsy/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_NQFjnWFUBs from "/home/runner/work/delivery-doves/delivery-doves/node_modules/.pnpm/nuxt-site-config@2.2.18_magicast@0.3.5_rollup@4.22.4_vite@5.4.7_@types+node@20.16.10_sass@1.5_euzv5subexruxze64bbgwpkdey/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_ZJQJJzmuVY from "/home/runner/work/delivery-doves/delivery-doves/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.16.10_ioredis@5.4.1_magicast@0.3.5_rollup@4._wmtra2nrrahpfyj5gbmobsxqsy/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_ykaSlz3cwH from "/home/runner/work/delivery-doves/delivery-doves/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.16.10_ioredis@5.4.1_magicast@0.3.5_rollup@4._wmtra2nrrahpfyj5gbmobsxqsy/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_AF9ybJabSr from "/home/runner/work/delivery-doves/delivery-doves/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.16.10_ioredis@5.4.1_magicast@0.3.5_rollup@4._wmtra2nrrahpfyj5gbmobsxqsy/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_e22Xy371J1 from "/home/runner/work/delivery-doves/delivery-doves/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.16.10_ioredis@5.4.1_magicast@0.3.5_rollup@4._wmtra2nrrahpfyj5gbmobsxqsy/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_ZJ0nkiYEgM from "/home/runner/work/delivery-doves/delivery-doves/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.22.4_typescript@5.6.2_vue@3.5.8_typescript@5.6.2__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/delivery-doves/delivery-doves/.nuxt/components.plugin.mjs";
import prefetch_client_1xVTcQ7EUS from "/home/runner/work/delivery-doves/delivery-doves/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.16.10_ioredis@5.4.1_magicast@0.3.5_rollup@4._wmtra2nrrahpfyj5gbmobsxqsy/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_XFqzrLbil9 from "/home/runner/work/delivery-doves/delivery-doves/node_modules/.pnpm/@nuxt+icon@1.5.2_magicast@0.3.5_rollup@4.22.4_vite@5.4.7_@types+node@20.16.10_sass@1.55.0_ter_bewastcqmfo64r6pph32xmatvi/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import preview_client_BvoMXQEvZW from "/home/runner/work/delivery-doves/delivery-doves/node_modules/.pnpm/@nuxthq+studio@2.1.1_magicast@0.3.5_rollup@4.22.4_webpack-sources@3.2.3/node_modules/@nuxthq/studio/dist/runtime/plugins/preview.client.js";
import titles_7jL9ny8Bab from "/home/runner/work/delivery-doves/delivery-doves/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.23_h3@1.12.0_magicast@0.3.5_rollup@4.22.4_vite@5.4.7_@types+node@20.16.1_l5nvkpfu3j6dvhuw2rovgc2mfe/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import siteConfig_lD4E3jLT6b from "/home/runner/work/delivery-doves/delivery-doves/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_magicast@0.3.5_rollup@4.22.4_vite@5.4.7_@types+node@20.16.10_sass@_zdobsyensr2lb2lqbzisfg4rj4/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_9QZKAnYGvq from "/home/runner/work/delivery-doves/delivery-doves/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_magicast@0.3.5_rollup@4.22.4_vite@5.4.7_@types+node@20.16.10_sass@_zdobsyensr2lb2lqbzisfg4rj4/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import plugin_AMo33dVcyp from "/home/runner/work/delivery-doves/delivery-doves/node_modules/.pnpm/nuxt-csurf@1.6.2_magicast@0.3.5_rollup@4.22.4_webpack-sources@3.2.3/node_modules/nuxt-csurf/dist/runtime/plugin.js";
import sounds_3v7rSoX5Sl from "/home/runner/work/delivery-doves/delivery-doves/node_modules/.pnpm/@vueuse+sound@2.0.1_vue@3.5.8_typescript@5.6.2_/node_modules/@vueuse/sound/dist/runtime/plugins/sounds.ts";
import authenticated_fetch_client_kRaJ5FV55P from "/home/runner/work/delivery-doves/delivery-doves/plugins/authenticated-fetch.client.ts";
import firebase_client_zXNDa0wxFH from "/home/runner/work/delivery-doves/delivery-doves/plugins/firebase.client.ts";
import init_driver_store_client_bQc6iNNq6D from "/home/runner/work/delivery-doves/delivery-doves/plugins/init-driver-store.client.ts";
import init_vendor_store_client_9hhcheX78g from "/home/runner/work/delivery-doves/delivery-doves/plugins/init-vendor-store.client.ts";
import vee_validate_client_P40JVl0wNI from "/home/runner/work/delivery-doves/delivery-doves/plugins/vee-validate.client.ts";
import defaults_Xfqeh20tq1 from "/home/runner/work/delivery-doves/delivery-doves/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.23_h3@1.12.0_magicast@0.3.5_rollup@4.22.4_vite@5.4.7_@types+node@20.16.1_l5nvkpfu3j6dvhuw2rovgc2mfe/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.js";
export default [
  revive_payload_client_GkJiFtbmMd,
  unhead_Bx1BGvbjT7,
  router_Y2PcTd7Mt0,
  _0_siteConfig_NQFjnWFUBs,
  payload_client_ZJQJJzmuVY,
  navigation_repaint_client_ykaSlz3cwH,
  check_outdated_build_client_AF9ybJabSr,
  chunk_reload_client_e22Xy371J1,
  plugin_vue3_ZJ0nkiYEgM,
  components_plugin_KR1HBZs4kY,
  prefetch_client_1xVTcQ7EUS,
  plugin_XFqzrLbil9,
  preview_client_BvoMXQEvZW,
  titles_7jL9ny8Bab,
  siteConfig_lD4E3jLT6b,
  inferSeoMetaPlugin_9QZKAnYGvq,
  plugin_AMo33dVcyp,
  sounds_3v7rSoX5Sl,
  authenticated_fetch_client_kRaJ5FV55P,
  firebase_client_zXNDa0wxFH,
  init_driver_store_client_bQc6iNNq6D,
  init_vendor_store_client_9hhcheX78g,
  vee_validate_client_P40JVl0wNI,
  defaults_Xfqeh20tq1
]